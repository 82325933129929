import React, { useState, useEffect } from 'react';
import { TextField, Button, MenuItem, Typography, Container, Grid, Paper, Autocomplete } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import sdk from 'api-sdk'; // Ensure correct import path
import { toast } from 'react-toastify';

// Create a dark theme
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    background: {
      paper: '#121212',
      default: '#303030',
    },
  },
  typography: {
    h4: {
      color: '#ffffff',
    },
  },
});

const ScenarioCreation = () => {
  const [form, setForm] = useState({
    silverBulletId: '',
    silverBulletName: '', // Added silverBulletName
    scenarioDifficulty: '',
    scenarioImpact: '',
    tactic: '',
    description: '',
    status: 'Pending',
  });

  const [silverBullets, setSilverBullets] = useState([]); // To store the list of SilverBullets
  const [selectedBullet, setSelectedBullet] = useState(null); // Selected SilverBullet
  const [errors, setErrors] = useState({});

  useEffect(() => {
    // Fetch SilverBullet names from the server on component mount
    const fetchSilverBullets = async () => {
      try {
        const response = await sdk.silverBullets.get(); // Fetch all silver bullets
        setSilverBullets(response); // Set the silver bullets to state
      } catch (error) {
        console.error('Error fetching SilverBullets:', error);
      }
    };
    
    fetchSilverBullets();
  }, []);

  const difficultyOptions = ['Low', 'Medium', 'High','נמוך','בינוני','גבוה'];
  const impactOptions = ['Low', 'Medium', 'High','נמוך','בינוני','גבוה'];

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleBulletChange = (event, newValue) => {
    // When a SilverBullet is selected, update the SilverBulletId and SilverBulletName
    setSelectedBullet(newValue);
    if (newValue) {
      setForm({
        ...form,
        silverBulletId: newValue.id,      // Fill in the bullet ID
        silverBulletName: newValue.name   // Fill in the bullet Name
      });
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!form.silverBulletId) newErrors.silverBulletId = 'SilverBullet ID is required';
    if (!form.silverBulletName) newErrors.silverBulletName = 'SilverBullet name is required';
    if (!form.scenarioDifficulty) newErrors.scenarioDifficulty = 'Difficulty is required';
    if (!form.scenarioImpact) newErrors.scenarioImpact = 'Impact is required';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      await sdk.scenario.create(form); // Call SDK to create scenario
      toast.success('Scenario created successfully!');
      setForm({
        silverBulletId: '',
        silverBulletName: '', // Reset silverBulletName
        scenarioDifficulty: '',
        scenarioImpact: '',
        tactic: '',
        description: '',
        status: 'Pending',
      });
      setSelectedBullet(null); // Reset the selected bullet
    } catch (error) {
      console.error('Error creating scenario:', error);
      toast.error('Failed to create scenario.');
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Container maxWidth="md">
        <Paper elevation={3} style={{ padding: '2rem', marginTop: '2rem' }}>
          <Typography variant="h4" gutterBottom>Create a New Scenario</Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              
              {/* SilverBullet Name */}
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  options={silverBullets}
                  getOptionLabel={(option) => option.name || ''}
                  value={selectedBullet}
                  onChange={handleBulletChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="SilverBullet Name"
                      error={!!errors.silverBulletName}
                      helperText={errors.silverBulletName || ''}
                      required
                    />
                  )}
                />
              </Grid>

              {/* SilverBullet ID (Disabled) */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="SilverBullet ID"
                  name="silverBulletId"
                  value={form.silverBulletId}
                  onChange={handleChange}
                  error={!!errors.silverBulletId}
                  helperText={errors.silverBulletId || ''}
                  disabled
                  required
                />
              </Grid>

              {/* Scenario Difficulty */}
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  fullWidth
                  label="Scenario Difficulty"
                  name="scenarioDifficulty"
                  value={form.scenarioDifficulty}
                  onChange={handleChange}
                  error={!!errors.scenarioDifficulty}
                  helperText={errors.scenarioDifficulty || ''}
                  required
                >
                  {difficultyOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Scenario Impact */}
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  fullWidth
                  label="Scenario Impact"
                  name="scenarioImpact"
                  value={form.scenarioImpact}
                  onChange={handleChange}
                  error={!!errors.scenarioImpact}
                  helperText={errors.scenarioImpact || ''}
                  required
                >
                  {impactOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Tactic */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Tactic"
                  name="tactic"
                  value={form.tactic}
                  onChange={handleChange}
                />
              </Grid>

              {/* Description */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  name="description"
                  value={form.description}
                  onChange={handleChange}
                  multiline
                  rows={4}
                />
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Create Scenario
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default ScenarioCreation;
