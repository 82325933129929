import { request, requestDownload } from './client';
var model = 'eye-of-the-enemy/Companies';
var companies = {
  create: function create(companyData) {
    return request('post', "/".concat(model), companyData);
  },
  getAll: function getAll() {
    return request('get', "/".concat(model));
  },
  delete: function _delete(id) {
    return request('delete', "/".concat(model, "/").concat(id));
  },
  update: function update(id, companyData) {
    return request('put', "/".concat(model, "/").concat(id), companyData);
  },
  getBulletsByCompanyAndRunNumber: function getBulletsByCompanyAndRunNumber(companyName, runNumber) {
    console.log(companyName);
    console.log(runNumber);
    return request('get', "/".concat(model, "/getBulletsByCompanyAndRunNumber?companyName=").concat(companyName, "&runNumber=").concat(runNumber));
  },
  getScenariosByCompanyAndRunNumber: function getScenariosByCompanyAndRunNumber(companyName, runNumber) {
    return request('get', "/".concat(model, "/getScenariosByCompanyAndRunNumber?companyName=").concat(companyName, "&runNumber=").concat(runNumber));
  }
};
export default companies;