import React, { useState, useEffect } from 'react';
import { Button, FormGroup, Label, Input, Form, Container, Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import sdk from 'api-sdk'; // Ensure correct import path
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import ImageModule from 'docxtemplater-image-module-free';
import { saveAs } from 'file-saver';
import englishTemplate from './test.docx'

const ReportForge = () => {
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [runNumber, setRunNumber] = useState('');
    const [scenarios, setScenarios] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchCompanies(); // Fetch companies when the component mounts
    }, []);

    const fetchCompanies = async () => {
        try {
            const companiesData = await sdk.companies.getAll();
            setCompanies(companiesData);
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    };

    const fetchScenarios = async () => {
        if (!selectedCompany || !runNumber) {
            alert('Please select a company and enter a run number');
            return;
        }

        setLoading(true);
        setScenarios([]); // Reset scenarios before fetching new ones

        try {
            // Fetch scenarios based on the selected company and run number
            const scenariosData = await sdk.companies.getScenariosByCompanyAndRunNumber(selectedCompany, runNumber);

            // Fetch recommendations for each scenario
            const scenariosWithRecommendations = await Promise.all(
                scenariosData.scenarios.map(async (scenario) => {
                    const response = await sdk.silverBullets.getDefRuleIds(scenario.silverBulletId);
                    const defRuleIds = response.defRuleIds;

                    // Fetch and collect recommendations from each defRule
                    if (Array.isArray(defRuleIds)) {
                        const recommendations = await Promise.all(
                            defRuleIds.map(async (defRuleId) => {
                                const defRuleText = await sdk.defRules.getDefRuleText(defRuleId);

                                // Ensure systemName and mitigations are properly structured
                                const systemName = defRuleText.systemName.systemName || 'Unknown';
                                const mitigations = Array.isArray(defRuleText.systemName.mitigations)
                                    ? defRuleText.systemName.mitigations
                                    : [defRuleText.systemName.mitigations].filter(Boolean); // Ensure mitigations is an array

                                return { systemName, mitigations };
                            })
                        );
                        return { ...scenario, recommendations };
                    } else {
                        console.error("defRuleIds is not an array:", defRuleIds);
                        return { ...scenario, recommendations: [] }; // Return empty recommendations if no defRuleIds
                    }
                })
            );
            console.log(scenariosWithRecommendations);

            setScenarios(scenariosWithRecommendations);
        } catch (error) {
            console.error('Error fetching scenarios:', error);
        } finally {
            setLoading(false);
        }
    };



    const handleCompanyChange = (e) => {
        setSelectedCompany(e.target.value);
    };

    const handleRunNumberChange = (e) => {
        setRunNumber(e.target.value);
    };

    // Function to generate the .docx report
    const generateDocx = async () => {
        const templateResponse = await fetch(englishTemplate); // Replace with your template file path
        const templateArrayBuffer = await templateResponse.arrayBuffer();
        const zip = new PizZip(templateArrayBuffer);

        const imageModuleOptions = {
            centered: false,
            getImage: (tagValue, tagName) => {
                // Only handle imageTag, assuming 'chartImage' is passed in Base64 format
                if (tagName === 'imageTag') {
                    return atob(tagValue); // Decodes Base64 image
                }
            },
            getSize: (img, tagValue, tagName) => {
                // Handle imageTag size
                if (tagName === 'imageTag') {
                    return [100, 100]; // You can customize the image size here
                }
            },
        };

        const doc = new Docxtemplater()
            .attachModule(new ImageModule(imageModuleOptions))
            .loadZip(zip);

        // Fetch base64 chart images and embed them in the data
        const scenariosWithImages = await Promise.all(
            scenarios.map(async (scenario) => {
                try {
                    // Fetch the chart image in base64 format
                    const { base64Chart } = await sdk.silverBullets.getChartBase64(scenario.silverBulletId); // Ensure this is correct

                    // Add the base64 chart image to the scenario object
                    return {
                        ...scenario,
                        chartImage: base64Chart // Store base64 string
                    };
                } catch (error) {
                    console.error('Error fetching base64 chart:', error);
                    return { ...scenario, chartImage: '' }; // Handle case when image is not available
                }
            })
        );

        console.log(scenariosWithImages);

        doc.setData({
            companyName: selectedCompany,
            runNumber: runNumber,
            scenarios: scenariosWithImages.map((scenario, index) => ({
                ...scenario,
                scenarioNumber: index + 1,
                scenarioTitle: scenario.silverBulletName,
                scenarioDescription: scenario.description,
                // Pass chartImage to the template, where it will be referenced as 'imageTag'
                attackFlow: [{ imageTag: scenario.chartImage }], // Embed base64 image as 'imageTag'
                recommendations: scenario.recommendations
            }))
        });

        try {
            doc.render();
        } catch (error) {
            console.error('Error rendering document:', error);
        }

        const output = doc.getZip().generate({ type: 'blob' });
        saveAs(output, `${selectedCompany}_${runNumber}_Report.docx`);
    };



    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col md={6}>
                    <Card className="shadow-sm border-0">
                        <CardBody>
                            <CardTitle tag="h4" className="text-center mb-4">
                                Report Forge
                            </CardTitle>
                            <Form>
                                <FormGroup>
                                    <Label for="companySelect">Select Company</Label>
                                    <Input
                                        type="select"
                                        id="companySelect"
                                        value={selectedCompany}
                                        onChange={handleCompanyChange}
                                    >
                                        <option value="">Choose a company</option>
                                        {companies.map((company) => (
                                            <option key={company.companyID} value={company.companyName}>
                                                {company.companyName}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="runNumber">Run Number</Label>
                                    <Input
                                        type="text"
                                        id="runNumber"
                                        placeholder="Enter run number"
                                        value={runNumber}
                                        onChange={handleRunNumberChange}
                                    />
                                </FormGroup>
                                <Button color="primary" block onClick={fetchScenarios} disabled={loading}>
                                    {loading ? 'Loading...' : 'Fetch Scenarios'}
                                </Button>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row className="mt-5">
                <Col>
                    {scenarios && scenarios.length > 0 ? (
                        <Card className="shadow-sm border-0">
                            <CardBody>
                                <CardTitle tag="h5" className="text-center">
                                    Scenarios for Company: {selectedCompany} | Run Number: {runNumber}
                                </CardTitle>
                                {scenarios.map((scenario, index) => (
                                    <div key={index} className="mb-4">
                                        <CardText className="text-muted">
                                            {scenario.silverBulletName} - {scenario.description}
                                        </CardText>
                                        {/* Display the chart image */}
                                        <ChartImage silverBulletId={scenario.silverBulletId} />
                                        {/* Display recommendations */}
                                        {scenario.recommendations && scenario.recommendations.length > 0 && (
                                            <div className="mt-3">
                                                <h5>Recommendations:</h5>
                                                {scenario.recommendations.map((recommendation, idx) => (
                                                    <div key={idx} className="mb-2">
                                                        <p><strong>System Name:</strong> {recommendation.systemName}</p>
                                                        {recommendation.mitigations && recommendation.mitigations.length > 0 && (
                                                            <ul>
                                                                {recommendation.mitigations.map((mitigation, i) => (
                                                                    <li key={i}>{mitigation}</li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <Button color="success" onClick={generateDocx}>
                                    Generate Report
                                </Button>
                            </CardBody>
                        </Card>
                    ) : (
                        <Card className="shadow-sm border-0">
                            <CardBody>
                                <CardText className="text-center">
                                    No scenarios found for the selected company and run number.
                                </CardText>
                            </CardBody>
                        </Card>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

// Component to fetch and display the chart image
const ChartImage = ({ silverBulletId }) => {
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        const fetchImage = async () => {
            const url = await sdk.silverBullets.getChartLink(silverBulletId);
            setImageUrl(url);
        };
        fetchImage();
    }, [silverBulletId]);

    return (
        <>
            {imageUrl ? (
                <img src={imageUrl} alt={`Chart for SilverBullet ${silverBulletId}`} className="img-fluid" />
            ) : (
                <p>Chart image not available</p>
            )}
        </>
    );
};

export default ReportForge;
